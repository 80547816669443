
import { defineComponent, reactive } from 'vue';
import { useRoute } from "vue-router";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const route = useRoute();
    const slug: any = route.params.slug;
    const routeName = route.name;
    const type = routeName === 'member-detail-tips-and-trik' ? 'video' : 'pdf';
    const detail = reactive({
      data: {},
      embed: "",
    })

    // console.log('route now ', route.name);
    // console.log('type and slug ', type , slug);

    const getData = async () => {
      const url = await api.getConfigFeatureMember('memberMateri', 'featureMateri') + `${type}/${slug}`;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      detail.data = rs;
    }
    getData();

    const getEmbed = (url : string) => {
      return gpf.getParameterByNameWL(url, 'v');
    }

    return {
      slug,
      type,
      detail,
      getEmbed,
    }
  }
})
